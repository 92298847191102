import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalComponent} from '@shared/confirmation-modal/confirmation-modal.component';
import {SimpleModalComponent} from '@shared/simple-modal/simple-modal.component';

import {ConfirmationDialogComponent} from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import {PromptDialogComponent} from 'src/app/shared/prompt-dialog/prompt-dialog.component';
import {CredentialService} from './credential.service';
import {TranslateService} from './translate.service';
import {PromptModalComponent} from '@shared/prompt-modal/prompt-modal.component';
import {PanelContainerService} from './panel-container.service';

export interface IConfirmationOptions {
  /**
   * dialog title, will be translated using
   * {{'confirmation' | translate : options.title}}
   */
  title: string;
  // text to show inside the confirmation dialog
  text: string;
  //show page warning icon
  iconVisible?: boolean,
  // dont decorate text and title
  noDecorate?: boolean;
  //flex options for footer container
  footerFlexboxAlignment?: string;
  // array of buttons to show at the bottom
  buttons: {
    /**
     * button text, will be translated using
     * {{'confirmation' | translate : button.text}}
     */
    wrappingClass?: string,
    text: string,
    // class to add to the button
    class?: string,
    // button callback
    callback(): any
  }[];

  closeCallback?(): any;
}

export interface IPromptOptions {
  /**
   * dialog title, will be shown as is using
   * {{ options.title }}
   */
  title: string;

  callback?(arg0: any): any;

  // array of buttons to show at the bottom
  buttons?: {
    /**
     * button text, will be translated using
     * {{'confirmation' | translate : button.text}}
     */
    text: string,
    // class to add to the button
    class?: string,
    // button callback
    callback(): any
  }[]

  //show modal regardless of the status
  overrideModalOpenStatus?: boolean;
}

/**
 * Service to handle generic confirmation modals.
 */
@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {
  private modalOpened: boolean = false;
  modalRef?: any;

  constructor(
    private modalService: NgbModal,
    private credentialService: CredentialService,
    private translateService: TranslateService,
    private panelContainerService: PanelContainerService) {
  }

  show(options: IConfirmationOptions) {
    if (this.modalOpened) {
      return;
    }

    this.modalOpened = true;

    // Update button classes based on isAltComponentDisplayActive
    const updatedButtons = options.buttons.map(button => ({
      ...button,
      class: this.getButtonClass(button.class, this.isAltComponentDisplayActive),
      wrappingClass: 'flex-1'
    }));

    const updatedOptions = {
      ...options,
      buttons: updatedButtons
    };

    if (this.isAltComponentDisplayActive) {
      this.modalRef = this.modalService.open(SimpleModalComponent, {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        fullscreen: 'sm',
        modalDialogClass: 'modal-decorator confirmation'
      });

      const translatedTitle = this.translateService.translate('confirmation', updatedOptions.title);
      updatedOptions.title = translatedTitle;
      updatedOptions.noDecorate = true;

      this.modalRef.componentInstance.prepareContent(
        ConfirmationModalComponent, { options: updatedOptions }
      );
    } else {
      this.modalRef = this.modalService.open(ConfirmationDialogComponent, {
        centered: true,
        modalDialogClass: 'modal-padding'
      });
      
      this.modalRef.componentInstance.options = updatedOptions;
    }

    this.modalRef.result.finally(() => {
      this.modalOpened = false;
    });
  }

  showConfirmation(options: IConfirmationOptions, modalOptions: any) {

    // open the dialog
    if (!this.modalOpened) {
      this.modalOpened = true;
      this.modalRef = this.modalService.open(ConfirmationDialogComponent, {
        ...modalOptions,
        centered: true
      });
      // pass the confirmation options
      this.modalRef.componentInstance.options = options;

      this.modalRef.result.finally(() => {
        this.modalOpened = false;
      });
    }
  }

  prompt(options: IPromptOptions) {
    // open the dialog
    if (!this.modalOpened || options.overrideModalOpenStatus) {
      this.modalOpened = true;

      if (this.isAltComponentDisplayActive) {
        this.modalRef = this.modalService.open(SimpleModalComponent, {
          backdrop: 'static',
          keyboard: false,
          centered: true,
          fullscreen: 'sm'
        });

        const translatedTitle = this.translateService.translate('confirmation', options.title);
        options = {...options, title: translatedTitle};

        this.modalRef.componentInstance.prepareContent(
          PromptModalComponent, {options});
      } else {
        this.modalRef = this.modalService.open(PromptDialogComponent, {
          centered: true,
          modalDialogClass: 'modal-padding'
        });
        // pass the confirmation options
        this.modalRef.componentInstance.options = options;
      }

      this.modalRef.result.finally(() => {
        this.modalOpened = false;
      });

    }

  }

  custom<TComp, TOptions>(content: TComp, options: TOptions & { modalOptions?: any }) {
    // open the dialog
    if (!this.modalOpened) {
      this.modalOpened = true;
      this.modalRef = this.modalService.open(content, {
        centered: true,
        ...(this.isAltComponentDisplayActive && !options.modalOptions 
          ? {fullscreen: 'sm', modalDialogClass: 'modal-decorator'} 
          : {modalDialogClass: 'modal-padding'}),
        ...options.modalOptions
      });
      // pass the confirmation options
      this.modalRef.componentInstance.options = options;

      this.modalRef.result.finally(() => {
        this.modalOpened = false;
      });
    }
  }

  close() {
    this.modalOpened = false;
    this.modalRef.close();
  }

  private get isAltComponentDisplayActive() {
    const user = this.credentialService.getCurrentUser.value;
    return user.altComponentDisplayActive;
  }

  private getButtonClass(originalClass: string, isAltDisplay: boolean): string {
    const classMap = {
      'btn__warning': isAltDisplay ? 'btn-cancel min-w-120' : 'btn__warning',
      'btn__green': isAltDisplay ? 'btn-success min-w-120' : 'btn__green'
    };

    const currentClassInMap = Object.keys(classMap).find(cls => originalClass?.includes(cls));

    if (currentClassInMap) {
      const replacementClass = classMap[currentClassInMap];
      return originalClass.replace(currentClassInMap, replacementClass);
    }

    return originalClass;
  }
}
